/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CutPacking
 */
export interface CutPacking {
    /**
     * 
     * @type {string}
     * @memberof CutPacking
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof CutPacking
     */
    weight: number;
    /**
     * 
     * @type {string}
     * @memberof CutPacking
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof CutPacking
     */
    locationId: string;
    /**
     * 
     * @type {Date}
     * @memberof CutPacking
     */
    cuttingDay: Date;
    /**
     * 
     * @type {Date}
     * @memberof CutPacking
     */
    sowingDay: Date;
    /**
     * 
     * @type {string}
     * @memberof CutPacking
     */
    producer: string;
    /**
     * 
     * @type {string}
     * @memberof CutPacking
     */
    contactInformation: string;
    /**
     * 
     * @type {number}
     * @memberof CutPacking
     */
    gutterCount: number;
    /**
     * 
     * @type {number}
     * @memberof CutPacking
     */
    gutterHoleCount: number;
    /**
     * 
     * @type {string}
     * @memberof CutPacking
     */
    storageCondition: string;
}

export function CutPackingFromJSON(json: any): CutPacking {
    return CutPackingFromJSONTyped(json, false);
}

export function CutPackingFromJSONTyped(json: any, ignoreDiscriminator: boolean): CutPacking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'weight': json['weight'],
        'productId': json['productId'],
        'locationId': json['locationId'],
        'cuttingDay': (new Date(json['cuttingDay'])),
        'sowingDay': (new Date(json['sowingDay'])),
        'producer': json['producer'],
        'contactInformation': json['contactInformation'],
        'gutterCount': json['gutterCount'],
        'gutterHoleCount': json['gutterHoleCount'],
        'storageCondition': json['storageCondition'],
    };
}

export function CutPackingToJSON(value?: CutPacking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'weight': value.weight,
        'productId': value.productId,
        'locationId': value.locationId,
        'cuttingDay': (value.cuttingDay.toISOString()),
        'sowingDay': (value.sowingDay.toISOString()),
        'producer': value.producer,
        'contactInformation': value.contactInformation,
        'gutterCount': value.gutterCount,
        'gutterHoleCount': value.gutterHoleCount,
        'storageCondition': value.storageCondition,
    };
}


