/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Facility,
    FacilityFromJSON,
    FacilityToJSON,
    Pest,
    PestFromJSON,
    PestToJSON,
} from '../models';

export interface CreatePestRequest {
    pest: Pest;
    facility: Facility;
}

export interface DeletePestRequest {
    facility: Facility;
    pestId: string;
}

export interface FindPestRequest {
    facility: Facility;
    pestId: string;
}

export interface ListPestsRequest {
    facility: Facility;
    firstResult?: number;
    maxResults?: number;
}

export interface UpdatePestRequest {
    pest: Pest;
    facility: Facility;
    pestId: string;
}

/**
 * 
 */
export class PestsApi extends runtime.BaseAPI {

    /**
     * Create new pest
     */
    async createPestRaw(requestParameters: CreatePestRequest): Promise<runtime.ApiResponse<Pest>> {
        if (requestParameters.pest === null || requestParameters.pest === undefined) {
            throw new runtime.RequiredError('pest','Required parameter requestParameters.pest was null or undefined when calling createPest.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling createPest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/pests`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PestToJSON(requestParameters.pest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PestFromJSON(jsonValue));
    }

    /**
     * Create new pest
     */
    async createPest(requestParameters: CreatePestRequest): Promise<Pest> {
        const response = await this.createPestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a pest
     */
    async deletePestRaw(requestParameters: DeletePestRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling deletePest.');
        }

        if (requestParameters.pestId === null || requestParameters.pestId === undefined) {
            throw new runtime.RequiredError('pestId','Required parameter requestParameters.pestId was null or undefined when calling deletePest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/pests/{pestId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"pestId"}}`, encodeURIComponent(String(requestParameters.pestId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a pest
     */
    async deletePest(requestParameters: DeletePestRequest): Promise<void> {
        await this.deletePestRaw(requestParameters);
    }

    /**
     * Find a pest
     */
    async findPestRaw(requestParameters: FindPestRequest): Promise<runtime.ApiResponse<Pest>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling findPest.');
        }

        if (requestParameters.pestId === null || requestParameters.pestId === undefined) {
            throw new runtime.RequiredError('pestId','Required parameter requestParameters.pestId was null or undefined when calling findPest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/pests/{pestId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"pestId"}}`, encodeURIComponent(String(requestParameters.pestId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PestFromJSON(jsonValue));
    }

    /**
     * Find a pest
     */
    async findPest(requestParameters: FindPestRequest): Promise<Pest> {
        const response = await this.findPestRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all pests
     */
    async listPestsRaw(requestParameters: ListPestsRequest): Promise<runtime.ApiResponse<Array<Pest>>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling listPests.');
        }

        const queryParameters: any = {};

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResults !== undefined) {
            queryParameters['maxResults'] = requestParameters.maxResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/pests`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PestFromJSON));
    }

    /**
     * List all pests
     */
    async listPests(requestParameters: ListPestsRequest): Promise<Array<Pest>> {
        const response = await this.listPestsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a pest
     */
    async updatePestRaw(requestParameters: UpdatePestRequest): Promise<runtime.ApiResponse<Pest>> {
        if (requestParameters.pest === null || requestParameters.pest === undefined) {
            throw new runtime.RequiredError('pest','Required parameter requestParameters.pest was null or undefined when calling updatePest.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling updatePest.');
        }

        if (requestParameters.pestId === null || requestParameters.pestId === undefined) {
            throw new runtime.RequiredError('pestId','Required parameter requestParameters.pestId was null or undefined when calling updatePest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/pests/{pestId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"pestId"}}`, encodeURIComponent(String(requestParameters.pestId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PestToJSON(requestParameters.pest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PestFromJSON(jsonValue));
    }

    /**
     * Updates a pest
     */
    async updatePest(requestParameters: UpdatePestRequest): Promise<Pest> {
        const response = await this.updatePestRaw(requestParameters);
        return await response.value();
    }

}
