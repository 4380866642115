/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Facility,
    FacilityFromJSON,
    FacilityToJSON,
    Packing,
    PackingFromJSON,
    PackingToJSON,
} from '../models';

export interface CreatePackingRequest {
    packing: Packing;
    facility: Facility;
}

export interface DeletePackingRequest {
    facility: Facility;
    packingId: string;
}

export interface FindPackingRequest {
    facility: Facility;
    packingId: string;
}

export interface ListPackingsRequest {
    facility: Facility;
    firstResult?: number;
    maxResults?: number;
    productId?: string;
    campaingId?: string;
    createdBefore?: string;
    createdAfter?: string;
}

export interface UpdatePackingRequest {
    packing: Packing;
    facility: Facility;
    packingId: string;
}

/**
 * 
 */
export class PackingsApi extends runtime.BaseAPI {

    /**
     * Create new packing
     */
    async createPackingRaw(requestParameters: CreatePackingRequest): Promise<runtime.ApiResponse<Packing>> {
        if (requestParameters.packing === null || requestParameters.packing === undefined) {
            throw new runtime.RequiredError('packing','Required parameter requestParameters.packing was null or undefined when calling createPacking.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling createPacking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/packings`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PackingToJSON(requestParameters.packing),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PackingFromJSON(jsonValue));
    }

    /**
     * Create new packing
     */
    async createPacking(requestParameters: CreatePackingRequest): Promise<Packing> {
        const response = await this.createPackingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a packing
     */
    async deletePackingRaw(requestParameters: DeletePackingRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling deletePacking.');
        }

        if (requestParameters.packingId === null || requestParameters.packingId === undefined) {
            throw new runtime.RequiredError('packingId','Required parameter requestParameters.packingId was null or undefined when calling deletePacking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/packings/{packingId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"packingId"}}`, encodeURIComponent(String(requestParameters.packingId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a packing
     */
    async deletePacking(requestParameters: DeletePackingRequest): Promise<void> {
        await this.deletePackingRaw(requestParameters);
    }

    /**
     * Find a packing
     */
    async findPackingRaw(requestParameters: FindPackingRequest): Promise<runtime.ApiResponse<Packing>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling findPacking.');
        }

        if (requestParameters.packingId === null || requestParameters.packingId === undefined) {
            throw new runtime.RequiredError('packingId','Required parameter requestParameters.packingId was null or undefined when calling findPacking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/packings/{packingId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"packingId"}}`, encodeURIComponent(String(requestParameters.packingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PackingFromJSON(jsonValue));
    }

    /**
     * Find a packing
     */
    async findPacking(requestParameters: FindPackingRequest): Promise<Packing> {
        const response = await this.findPackingRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all packings
     */
    async listPackingsRaw(requestParameters: ListPackingsRequest): Promise<runtime.ApiResponse<Array<Packing>>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling listPackings.');
        }

        const queryParameters: any = {};

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResults !== undefined) {
            queryParameters['maxResults'] = requestParameters.maxResults;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.campaingId !== undefined) {
            queryParameters['campaingId'] = requestParameters.campaingId;
        }

        if (requestParameters.createdBefore !== undefined) {
            queryParameters['createdBefore'] = requestParameters.createdBefore;
        }

        if (requestParameters.createdAfter !== undefined) {
            queryParameters['createdAfter'] = requestParameters.createdAfter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/packings`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PackingFromJSON));
    }

    /**
     * List all packings
     */
    async listPackings(requestParameters: ListPackingsRequest): Promise<Array<Packing>> {
        const response = await this.listPackingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a packing
     */
    async updatePackingRaw(requestParameters: UpdatePackingRequest): Promise<runtime.ApiResponse<Packing>> {
        if (requestParameters.packing === null || requestParameters.packing === undefined) {
            throw new runtime.RequiredError('packing','Required parameter requestParameters.packing was null or undefined when calling updatePacking.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling updatePacking.');
        }

        if (requestParameters.packingId === null || requestParameters.packingId === undefined) {
            throw new runtime.RequiredError('packingId','Required parameter requestParameters.packingId was null or undefined when calling updatePacking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/packings/{packingId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"packingId"}}`, encodeURIComponent(String(requestParameters.packingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PackingToJSON(requestParameters.packing),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PackingFromJSON(jsonValue));
    }

    /**
     * Updates a packing
     */
    async updatePacking(requestParameters: UpdatePackingRequest): Promise<Packing> {
        const response = await this.updatePackingRaw(requestParameters);
        return await response.value();
    }

}
