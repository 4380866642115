/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Batch,
    BatchFromJSON,
    BatchToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Facility,
    FacilityFromJSON,
    FacilityToJSON,
} from '../models';

export interface CreateBatchRequest {
    batch: Batch;
    facility: Facility;
}

export interface DeleteBatchRequest {
    facility: Facility;
    batchId: string;
}

export interface FindBatchRequest {
    facility: Facility;
    batchId: string;
}

export interface ListBatchesRequest {
    facility: Facility;
    productId?: string;
    firstResult?: number;
    maxResult?: number;
    createdBefore?: string;
    createdAfter?: string;
}

export interface UpdateBatchRequest {
    batch: Batch;
    facility: Facility;
    batchId: string;
}

/**
 * 
 */
export class BatchesApi extends runtime.BaseAPI {

    /**
     * Create new batch
     */
    async createBatchRaw(requestParameters: CreateBatchRequest): Promise<runtime.ApiResponse<Batch>> {
        if (requestParameters.batch === null || requestParameters.batch === undefined) {
            throw new runtime.RequiredError('batch','Required parameter requestParameters.batch was null or undefined when calling createBatch.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling createBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/batches`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BatchToJSON(requestParameters.batch),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchFromJSON(jsonValue));
    }

    /**
     * Create new batch
     */
    async createBatch(requestParameters: CreateBatchRequest): Promise<Batch> {
        const response = await this.createBatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a batch
     */
    async deleteBatchRaw(requestParameters: DeleteBatchRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling deleteBatch.');
        }

        if (requestParameters.batchId === null || requestParameters.batchId === undefined) {
            throw new runtime.RequiredError('batchId','Required parameter requestParameters.batchId was null or undefined when calling deleteBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/batches/{batchId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a batch
     */
    async deleteBatch(requestParameters: DeleteBatchRequest): Promise<void> {
        await this.deleteBatchRaw(requestParameters);
    }

    /**
     * Find a batch
     */
    async findBatchRaw(requestParameters: FindBatchRequest): Promise<runtime.ApiResponse<Batch>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling findBatch.');
        }

        if (requestParameters.batchId === null || requestParameters.batchId === undefined) {
            throw new runtime.RequiredError('batchId','Required parameter requestParameters.batchId was null or undefined when calling findBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/batches/{batchId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchFromJSON(jsonValue));
    }

    /**
     * Find a batch
     */
    async findBatch(requestParameters: FindBatchRequest): Promise<Batch> {
        const response = await this.findBatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all batches
     */
    async listBatchesRaw(requestParameters: ListBatchesRequest): Promise<runtime.ApiResponse<Array<Batch>>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling listBatches.');
        }

        const queryParameters: any = {};

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResult !== undefined) {
            queryParameters['maxResult'] = requestParameters.maxResult;
        }

        if (requestParameters.createdBefore !== undefined) {
            queryParameters['createdBefore'] = requestParameters.createdBefore;
        }

        if (requestParameters.createdAfter !== undefined) {
            queryParameters['createdAfter'] = requestParameters.createdAfter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/batches`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BatchFromJSON));
    }

    /**
     * List all batches
     */
    async listBatches(requestParameters: ListBatchesRequest): Promise<Array<Batch>> {
        const response = await this.listBatchesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a batch
     */
    async updateBatchRaw(requestParameters: UpdateBatchRequest): Promise<runtime.ApiResponse<Batch>> {
        if (requestParameters.batch === null || requestParameters.batch === undefined) {
            throw new runtime.RequiredError('batch','Required parameter requestParameters.batch was null or undefined when calling updateBatch.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling updateBatch.');
        }

        if (requestParameters.batchId === null || requestParameters.batchId === undefined) {
            throw new runtime.RequiredError('batchId','Required parameter requestParameters.batchId was null or undefined when calling updateBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/batches/{batchId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BatchToJSON(requestParameters.batch),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchFromJSON(jsonValue));
    }

    /**
     * Updates a batch
     */
    async updateBatch(requestParameters: UpdateBatchRequest): Promise<Batch> {
        const response = await this.updateBatchRaw(requestParameters);
        return await response.value();
    }

}
