/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PackingContainerType,
    PackingContainerTypeFromJSON,
    PackingContainerTypeFromJSONTyped,
    PackingContainerTypeToJSON,
    PackingType,
    PackingTypeFromJSON,
    PackingTypeFromJSONTyped,
    PackingTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Packing
 */
export interface Packing {
    /**
     * 
     * @type {string}
     * @memberof Packing
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Packing
     */
    startTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof Packing
     */
    endTime: Date;
    /**
     * 
     * @type {string}
     * @memberof Packing
     */
    sourceLocation: string;
    /**
     * 
     * @type {string}
     * @memberof Packing
     */
    targetLocation: string;
    /**
     * 
     * @type {string}
     * @memberof Packing
     */
    batchId: string;
    /**
     * 
     * @type {number}
     * @memberof Packing
     */
    largeTrayAmount: number;
    /**
     * 
     * @type {PackingContainerType}
     * @memberof Packing
     */
    containerType: PackingContainerType;
    /**
     * 
     * @type {number}
     * @memberof Packing
     */
    boxAmount: number;
    /**
     * 
     * @type {PackingType}
     * @memberof Packing
     */
    type: PackingType;
    /**
     * 
     * @type {number}
     * @memberof Packing
     */
    personCount: number;
    /**
     * 
     * @type {string}
     * @memberof Packing
     */
    additionalInformation: string;
}

export function PackingFromJSON(json: any): Packing {
    return PackingFromJSONTyped(json, false);
}

export function PackingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Packing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'startTime': (new Date(json['startTime'])),
        'endTime': (new Date(json['endTime'])),
        'sourceLocation': json['sourceLocation'],
        'targetLocation': json['targetLocation'],
        'batchId': json['batchId'],
        'largeTrayAmount': json['largeTrayAmount'],
        'containerType': PackingContainerTypeFromJSON(json['containerType']),
        'boxAmount': json['boxAmount'],
        'type': PackingTypeFromJSON(json['type']),
        'personCount': json['personCount'],
        'additionalInformation': json['additionalInformation'],
    };
}

export function PackingToJSON(value?: Packing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'startTime': (value.startTime.toISOString()),
        'endTime': (value.endTime.toISOString()),
        'sourceLocation': value.sourceLocation,
        'targetLocation': value.targetLocation,
        'batchId': value.batchId,
        'largeTrayAmount': value.largeTrayAmount,
        'containerType': PackingContainerTypeToJSON(value.containerType),
        'boxAmount': value.boxAmount,
        'type': PackingTypeToJSON(value.type),
        'personCount': value.personCount,
        'additionalInformation': value.additionalInformation,
    };
}


