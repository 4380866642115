import * as React from "react";
import Keycloak from 'keycloak-js';
import * as actions from "../actions";
import { ErrorMessage, StoreState } from "../types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Api from "../api";
import { Facility, Location, LocationType } from "../generated/client";
import { Navigate } from 'react-router-dom';

import {
  Grid,
  Button,
  Form,
  Input
} from "semantic-ui-react";
import strings from "../localization/strings";
import { FormContainer } from "./FormContainer";

interface Props {
  keycloak?: Keycloak;
  location?: Location;
  facility: Facility;
  onLocationCreated?: (location: Location) => void,
  onError: (error: ErrorMessage | undefined) => void;
}

interface State {
  name: string
  capacity: number
  capacityInUse: number
  type: LocationType
  redirect: boolean
}

class CreateLocation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
        name: "",
        capacity: 0,
        capacityInUse: 0,
        type: LocationType.PlasticRoom,
        redirect: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * Handle form submit
   */
  private async handleSubmit() {
    const { keycloak, facility, onError } = this.props;
    const { name, type, capacity, capacityInUse } = this.state;
    try {
      if (!keycloak) {
        return;
      }
      
      const locationObject: Location = {
        name: name,
        type: type as LocationType,
        capacity: capacity,
        capacityInUse: capacityInUse,
      };
  
      const locationsService = await Api.getLocationsService(keycloak);
      await locationsService.createLocation({
        location: locationObject,
        facility: facility
      });
  
      this.setState({redirect: true});
    } catch (e: any) {
      onError({
        message: strings.defaultApiErrorMessage,
        title: strings.defaultApiErrorTitle,
        exception: e
      });
    }
  }

  /**
   * Render create production line view
   */
  public render() {
    if (this.state.redirect) {
      return <Navigate replace={true} to="/productionLines"/>;
    }

    return (
      <Grid>
        <Grid.Row className="content-page-header-row">
          <Grid.Column width={8}>
            <h2>{strings.newLocation}</h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <FormContainer>
              <Form.Field required>
                <label>{strings.productionLineNumber}</label>
                <Input 
                  value={this.state.name} 
                  placeholder={strings.productionLineNumber}
                  onChange={(e) => this.setState({name: e.currentTarget.value})}
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.productionLineDefaultGutterHoleCount}</label>
                <Input 
                  type="number"
                  value={this.state.capacity} 
                  placeholder={strings.productionLineDefaultGutterHoleCount}
                  onChange={(e) => this.setState({capacity: parseInt(e.currentTarget.value)})}
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.productionLineDefaultGutterHoleCount}</label>
                <Input 
                  type="number"
                  value={this.state.capacityInUse} 
                  placeholder={strings.productionLineDefaultGutterHoleCount}
                  onChange={(e) => this.setState({capacityInUse: parseInt(e.currentTarget.value)})}
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.productionLineDefaultGutterHoleCount}</label>
                <Form.Select 
                  options={[
                    { key: LocationType.PlasticRoom, text: LocationType.PlasticRoom, value: LocationType.PlasticRoom },
                    { key: LocationType.Ramp, text: LocationType.Ramp, value: LocationType.Ramp }
                  ]}
                  value={this.state.type}
                  onChange={(e, { value }) => this.setState({type: value as LocationType})}
                />
              </Form.Field>
              <Button className="submit-button" onClick={this.handleSubmit} type='submit'>{strings.save}</Button>
            </FormContainer>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

/**
 * Redux mapper for mapping store state to component props
 * 
 * @param state store state
 */
export function mapStateToProps(state: StoreState) {
  return {
    locations: state.locations,
    location: state.location,
    facility: state.facility
  };
}

/**
 * Redux mapper for mapping component dispatches 
 * 
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<actions.AppAction>) {
  return {
    onLocationCreated: (location: Location) => dispatch(actions.locationCreated(location)),
     onError: (error: ErrorMessage | undefined) => dispatch(actions.onErrorOccurred(error))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLocation);