export const USER_LOGIN = "USER_LOGIN";
export type USER_LOGIN = typeof USER_LOGIN;

export const USER_LOGOUT = "USER_LOGOUT";
export type USER_LOGOUT = typeof USER_LOGOUT;

export const PRODUCTS_FOUND= "PRODUCTS_FOUND";
export type PRODUCTS_FOUND = typeof PRODUCTS_FOUND;

export const PRODUCT_SELECTED = "PRODUCT_SELECTED";
export type PRODUCT_SELECTED = typeof PRODUCT_SELECTED;

export const PRODUCT_CREATED= "PRODUCT_CREATED";
export type PRODUCT_CREATED = typeof PRODUCT_CREATED;

export const PRODUCT_DELETED= "PRODUCT_DELETED";
export type PRODUCT_DELETED = typeof PRODUCT_DELETED;

export const PACKAGE_SIZES_FOUND= "PACKAGE_SIZES_FOUND";
export type PACKAGE_SIZES_FOUND = typeof PACKAGE_SIZES_FOUND;

export const PACKAGE_SIZE_SELECTED = "PACKAGE_SIZE_SELECTED";
export type PACKAGE_SIZE_SELECTED = typeof PACKAGE_SIZE_SELECTED;

export const PACKAGE_SIZE_CREATED= "PACKAGE_SIZE_CREATED";
export type PACKAGE_SIZE_CREATED = typeof PACKAGE_SIZE_CREATED;

export const PACKAGE_SIZE_DELETED = "PACKAGE_SIZE_DELETED";
export type PACKAGE_SIZE_DELETED = typeof PACKAGE_SIZE_DELETED;

export const SEEDS_FOUND = "SEEDS_FOUND";
export type SEEDS_FOUND = typeof SEEDS_FOUND;

export const SEED_SELECTED = "SEED_SELECTED";
export type SEED_SELECTED = typeof SEED_SELECTED;

export const SEED_CREATED = "SEED_CREATED";
export type SEED_CREATED = typeof SEED_CREATED;

export const SEED_DELETED = "SEED_DELETED";
export type SEED_DELETED = typeof SEED_DELETED;

export const LOCATIONS_FOUND = "LOCATIONS_FOUND";
export type LOCATIONS_FOUND = typeof LOCATIONS_FOUND;

export const LOCATION_SELECTED = "LOCATION_SELECTED";
export type LOCATION_SELECTED = typeof LOCATION_SELECTED;

export const LOCATION_CREATED = "LOCATION_CREATED";
export type LOCATION_CREATED = typeof LOCATION_CREATED;

export const LOCATION_DELETED = "LOCATION_DELETED";
export type LOCATION_DELETED = typeof LOCATION_DELETED;

export const SEED_BATCHES_FOUND= "SEED_BATCHES_FOUND";
export type SEED_BATCHES_FOUND = typeof SEED_BATCHES_FOUND;

export const SEED_BATCH_SELECTED = "SEED_BATCH_SELECTED";
export type SEED_BATCH_SELECTED = typeof SEED_BATCH_SELECTED;

export const SEED_BATCH_CREATED= "SEED_BATCH_CREATED";
export type SEED_BATCH_CREATED = typeof SEED_BATCH_CREATED;

export const SEED_BATCH_DELETED= "SEED_BATCH_DELETED";
export type SEED_BATCH_DELETED = typeof SEED_BATCH_DELETED;

export const PERFORMED_CULTIVATION_ACTIONS_FOUND = "PERFORMED_CULTIVATION_ACTIONS_FOUND";
export type PERFORMED_CULTIVATION_ACTIONS_FOUND = typeof PERFORMED_CULTIVATION_ACTIONS_FOUND;

export const PERFORMED_CULTIVATION_ACTION_SELECTED = "PERFORMED_CULTIVATION_ACTION_SELECTED";
export type PERFORMED_CULTIVATION_ACTION_SELECTED = typeof PERFORMED_CULTIVATION_ACTION_SELECTED;

export const PERFORMED_CULTIVATION_ACTION_CREATED= "PERFORMED_CULTIVATION_ACTION_CREATED";
export type PERFORMED_CULTIVATION_ACTION_CREATED = typeof PERFORMED_CULTIVATION_ACTION_CREATED;

export const PERFORMED_CULTIVATION_ACTION_DELETED= "PERFORMED_CULTIVATION_ACTION_DELETED";
export type PERFORMED_CULTIVATION_ACTION_DELETED = typeof PERFORMED_CULTIVATION_ACTION_DELETED;

export const LOCALE_UPDATE = "LOCALE_UPDATE";
export type LOCALE_UPDATE = typeof LOCALE_UPDATE;

export const FACILITY_UPDATE = "FACILITY_UPDATE";
export type FACILITY_UPDATE = typeof FACILITY_UPDATE;

export const WASTAGE_REASONS_FOUND= "WASTAGE_REASONS_FOUND";
export type WASTAGE_REASONS_FOUND = typeof WASTAGE_REASONS_FOUND;

export const WASTAGE_REASON_SELECTED = "WASTAGE_REASON_SELECTED";
export type WASTAGE_REASON_SELECTED = typeof WASTAGE_REASON_SELECTED;

export const WASTAGE_REASON_CREATED= "WASTAGE_REASON_CREATED";
export type WASTAGE_REASON_CREATED = typeof WASTAGE_REASON_CREATED;

export const WASTAGE_REASON_DELETED= "WASTAGE_REASON_DELETED";
export type WASTAGE_REASON_DELETED = typeof WASTAGE_REASON_DELETED;

export const PESTS_FOUND = "PESTS_FOUND";
export type PESTS_FOUND = typeof PESTS_FOUND;

export const ERROR_OCCURRED = "ERROR_OCCURRED";
export type ERROR_OCCURRED = typeof ERROR_OCCURRED;

export const PACKINGS_FOUND = "PACKINGS_FOUND";
export type PACKINGS_FOUND = typeof PACKINGS_FOUND;

export const CAMPAIGNS_FOUND = "CAMPAIGNS_FOUND";
export type CAMPAIGNS_FOUND = typeof CAMPAIGNS_FOUND;

export const EVENT_LIST_FILTERS_UPDATED = "EVENT_LIST_FILTERS_UPDATED";
export type EVENT_LIST_FILTERS_UPDATED = typeof EVENT_LIST_FILTERS_UPDATED;

export const EVENTS_FOUND = "EVENTS_FOUND";
export type EVENTS_FOUND = typeof EVENTS_FOUND;

/**
 * X axis times for labels/ ticks
 */
export const X_AXIS_TIMES = [6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21];