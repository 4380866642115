/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EventType {
    Planting = 'PLANTING',
    Transfer = 'TRANSFER',
    Sowing = 'SOWING',
    TableSpread = 'TABLE_SPREAD',
    CultivationObservation = 'CULTIVATION_OBSERVATION',
    Harvest = 'HARVEST',
    Wastage = 'WASTAGE'
}

export function EventTypeFromJSON(json: any): EventType {
    return EventTypeFromJSONTyped(json, false);
}

export function EventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventType {
    return json as EventType;
}

export function EventTypeToJSON(value?: EventType | null): any {
    return value as any;
}

