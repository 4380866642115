/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Facility,
    FacilityFromJSON,
    FacilityToJSON,
    Location,
    LocationFromJSON,
    LocationToJSON,
} from '../models';

export interface CreateLocationRequest {
    location: Location;
    facility: Facility;
}

export interface DeleteLocationRequest {
    facility: Facility;
    locationId: string;
}

export interface FindLocationRequest {
    facility: Facility;
    locationId: string;
}

export interface ListLocationsRequest {
    facility: Facility;
    firstResult?: number;
    maxResults?: number;
}

export interface UpdateLocationRequest {
    location: Location;
    facility: Facility;
    locationId: string;
}

/**
 * 
 */
export class LocationsApi extends runtime.BaseAPI {

    /**
     * Create new location
     */
    async createLocationRaw(requestParameters: CreateLocationRequest): Promise<runtime.ApiResponse<Location>> {
        if (requestParameters.location === null || requestParameters.location === undefined) {
            throw new runtime.RequiredError('location','Required parameter requestParameters.location was null or undefined when calling createLocation.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling createLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/locations`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LocationToJSON(requestParameters.location),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationFromJSON(jsonValue));
    }

    /**
     * Create new location
     */
    async createLocation(requestParameters: CreateLocationRequest): Promise<Location> {
        const response = await this.createLocationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a location
     */
    async deleteLocationRaw(requestParameters: DeleteLocationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling deleteLocation.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling deleteLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/locations/{locationId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a location
     */
    async deleteLocation(requestParameters: DeleteLocationRequest): Promise<void> {
        await this.deleteLocationRaw(requestParameters);
    }

    /**
     * Find a location
     */
    async findLocationRaw(requestParameters: FindLocationRequest): Promise<runtime.ApiResponse<Location>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling findLocation.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling findLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/locations/{locationId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationFromJSON(jsonValue));
    }

    /**
     * Find a location
     */
    async findLocation(requestParameters: FindLocationRequest): Promise<Location> {
        const response = await this.findLocationRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all locations
     */
    async listLocationsRaw(requestParameters: ListLocationsRequest): Promise<runtime.ApiResponse<Array<Location>>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling listLocations.');
        }

        const queryParameters: any = {};

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResults !== undefined) {
            queryParameters['maxResults'] = requestParameters.maxResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/locations`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LocationFromJSON));
    }

    /**
     * List all locations
     */
    async listLocations(requestParameters: ListLocationsRequest): Promise<Array<Location>> {
        const response = await this.listLocationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a location
     */
    async updateLocationRaw(requestParameters: UpdateLocationRequest): Promise<runtime.ApiResponse<Location>> {
        if (requestParameters.location === null || requestParameters.location === undefined) {
            throw new runtime.RequiredError('location','Required parameter requestParameters.location was null or undefined when calling updateLocation.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling updateLocation.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling updateLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/locations/{locationId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LocationToJSON(requestParameters.location),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationFromJSON(jsonValue));
    }

    /**
     * Updates a location
     */
    async updateLocation(requestParameters: UpdateLocationRequest): Promise<Location> {
        const response = await this.updateLocationRaw(requestParameters);
        return await response.value();
    }

}
