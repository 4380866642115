/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BatchStatus,
    BatchStatusFromJSON,
    BatchStatusFromJSONTyped,
    BatchStatusToJSON,
} from './';

/**
 * Identifies a single batch for it's entire life-cycle
 * @export
 * @interface Batch
 */
export interface Batch {
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    batchNumber: string;
    /**
     * 
     * @type {number}
     * @memberof Batch
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    parentBatchId?: string;
    /**
     * 
     * @type {BatchStatus}
     * @memberof Batch
     */
    status: BatchStatus;
    /**
     * 
     * @type {Date}
     * @memberof Batch
     */
    createdAt?: Date;
}

export function BatchFromJSON(json: any): Batch {
    return BatchFromJSONTyped(json, false);
}

export function BatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): Batch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'productId': json['productId'],
        'batchNumber': json['batchNumber'],
        'amount': json['amount'],
        'parentBatchId': !exists(json, 'parentBatchId') ? undefined : json['parentBatchId'],
        'status': BatchStatusFromJSON(json['status']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
    };
}

export function BatchToJSON(value?: Batch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'productId': value.productId,
        'batchNumber': value.batchNumber,
        'amount': value.amount,
        'parentBatchId': value.parentBatchId,
        'status': BatchStatusToJSON(value.status),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}


