import * as React from "react";
import * as actions from "../actions";
import "semantic-ui-css/semantic.min.css";
import "./styles.css";
import { Menu, Container, Icon, Sidebar, Message, Button } from "semantic-ui-react";
import strings from "../localization/strings";
import { ErrorMessage, StoreState } from "../types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Facility } from "../generated/client";
import Keycloak from "keycloak-js";
import ToggleLocalization from "./ToggleLocalization";

/**
 * Interface representing component properties
 */
interface Props {
  sidebarItems: JSX.Element[];
  error?: ErrorMessage;
  onError: (error?: ErrorMessage) => void;
  children: React.ReactNode;
  facility: Facility;
  keycloak?: Keycloak;
}

/**
 * Interface representing component state
 */
interface State {
  sidebarOpen: boolean;
  facility: Facility;
}

/**
 * React component for basic application layout
 */
class BasicLayout extends React.Component<Props, State> {
  /**
   * Constructor
   * @param props component properties
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      facility: props.facility,
    };
  }

  /**
   * Component did catch life-cycle event
   *
   * @param error error
   * @param errorInfo error info
   */
  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.props.onError({
      message: strings.defaultErrorMessage,
      title: strings.defaultErrorTitle,
      exception: error,
    });
  }

  /**
   * Handles sidebar toggling
   */
  private toggleSidebar = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
    });
  };

  /**
   * Get header text
   *
   * @returns header text
   */
  private getHeaderText = () => {
    return (
      <a href="/" style={{ color: "#000000", fontSize: 30 }}>
        <span>
          <strong>{strings.managementHeaderText1}</strong> {strings.managementHeaderText2}
        </span>
      </a>
    );
  };

  /**
   * Render basic layout
   */
  public render() {
    const tokenParsed = this.props.keycloak?.tokenParsed ?? {};
    const displayName = tokenParsed.name ? tokenParsed.name : tokenParsed.preferred_username;

    return (
      <div>
        <Container
          style={{
            background: "#fff",
            padding: 10,
            minHeight: "55px",
            width: "100%",
          }}
        >
          {this.getHeaderText()}
        </Container>
        <Menu
          inverted
          style={{
            background: "#000000",
            margin: 0,
            borderRadius: 0,
            height: "55px",
          }}
        >
          <Menu.Item onClick={this.toggleSidebar.bind(this)} name="bars">
            <Icon name="bars" />
          </Menu.Item>
          <ToggleLocalization />
          <Menu.Item position="right">
            <p style={{ margin: 0, padding: 0, marginRight: 20 }}>{displayName ?? ""}</p>
            <Button
              style={{ width: 150, backgroundColor: "#7ABA28", borderRadius: 100 }}
              onClick={() => this.props.keycloak?.logout()}
            >
              {strings.logoutUrl}
            </Button>
          </Menu.Item>
        </Menu>
        <div style={{ marginTop: "0", background: "transparent" }}>
          <Sidebar.Pushable>
            <Sidebar
              as={Menu}
              vertical
              visible={this.state.sidebarOpen}
              width="thin"
              animation="overlay"
              style={{ paddingTop: "4em" }}
              inverted
            >
              {this.props.sidebarItems}
            </Sidebar>
            <Sidebar.Pusher>
              <Container style={{ minHeight: "calc(100vh - 112px)", paddingTop: "6em", paddingBottom: "3em" }}>
                {this.renderError()}
                {this.props.children}
              </Container>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </div>
      </div>
    );
  }

  private renderError() {
    if (!this.props.error) {
      return null;
    }

    setTimeout(() => {
      this.props.onError(undefined);
    }, 10000);

    return (
      <Message negative>
        {this.props.error.title ? <Message.Header>{this.props.error.title}</Message.Header> : null}
        <p>
          {this.props.error.message ? this.props.error.message : ""}. {strings.errorRetryText}{" "}
          <a href="javascript:window.location.reload(true)">{strings.errorRetryHere}</a>
        </p>
        <p>{strings.errorSupportText}</p>
      </Message>
    );
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
export function mapStateToProps(state: StoreState) {
  return {
    error: state.error,
    facility: state.facility,
    keycloak: state.keycloak,
  };
}

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<actions.AppAction>) {
  return {
    onError: (error?: ErrorMessage) => dispatch(actions.onErrorOccurred(error)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicLayout);
