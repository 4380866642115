/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BatchStatus {
    Sowing = 'SOWING',
    Growth = 'GROWTH',
    Packed = 'PACKED',
    Sent = 'SENT'
}

export function BatchStatusFromJSON(json: any): BatchStatus {
    return BatchStatusFromJSONTyped(json, false);
}

export function BatchStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchStatus {
    return json as BatchStatus;
}

export function BatchStatusToJSON(value?: BatchStatus | null): any {
    return value as any;
}

