/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Metadata,
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './';

/**
 * 
 * @export
 * @interface SeedBatch
 */
export interface SeedBatch {
    /**
     * 
     * @type {string}
     * @memberof SeedBatch
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SeedBatch
     */
    seedId: string;
    /**
     * 
     * @type {string}
     * @memberof SeedBatch
     */
    species: string;
    /**
     * 
     * @type {string}
     * @memberof SeedBatch
     */
    svCode: string;
    /**
     * 
     * @type {number}
     * @memberof SeedBatch
     */
    thousandSeedWeight: number;
    /**
     * 
     * @type {number}
     * @memberof SeedBatch
     */
    reportedGermination: number;
    /**
     * 
     * @type {number}
     * @memberof SeedBatch
     */
    testedGermination: number;
    /**
     * 
     * @type {string}
     * @memberof SeedBatch
     */
    baseCertificateNumber: string;
    /**
     * 
     * @type {number}
     * @memberof SeedBatch
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof SeedBatch
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof SeedBatch
     */
    purity: number;
    /**
     * 
     * @type {string}
     * @memberof SeedBatch
     */
    containerNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SeedBatch
     */
    seedIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof SeedBatch
     */
    baseMaterialType: string;
    /**
     * 
     * @type {string}
     * @memberof SeedBatch
     */
    sourceArea: string;
    /**
     * 
     * @type {string}
     * @memberof SeedBatch
     */
    forestryClass: string;
    /**
     * 
     * @type {Metadata}
     * @memberof SeedBatch
     */
    metadata?: Metadata;
}

export function SeedBatchFromJSON(json: any): SeedBatch {
    return SeedBatchFromJSONTyped(json, false);
}

export function SeedBatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeedBatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'seedId': json['seedId'],
        'species': json['species'],
        'svCode': json['svCode'],
        'thousandSeedWeight': json['thousandSeedWeight'],
        'reportedGermination': json['reportedGermination'],
        'testedGermination': json['testedGermination'],
        'baseCertificateNumber': json['baseCertificateNumber'],
        'amount': json['amount'],
        'price': json['price'],
        'purity': json['purity'],
        'containerNumber': json['containerNumber'],
        'seedIdentifier': json['seedIdentifier'],
        'baseMaterialType': json['baseMaterialType'],
        'sourceArea': json['sourceArea'],
        'forestryClass': json['forestryClass'],
        'metadata': !exists(json, 'metadata') ? undefined : MetadataFromJSON(json['metadata']),
    };
}

export function SeedBatchToJSON(value?: SeedBatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'seedId': value.seedId,
        'species': value.species,
        'svCode': value.svCode,
        'thousandSeedWeight': value.thousandSeedWeight,
        'reportedGermination': value.reportedGermination,
        'testedGermination': value.testedGermination,
        'baseCertificateNumber': value.baseCertificateNumber,
        'amount': value.amount,
        'price': value.price,
        'purity': value.purity,
        'containerNumber': value.containerNumber,
        'seedIdentifier': value.seedIdentifier,
        'baseMaterialType': value.baseMaterialType,
        'sourceArea': value.sourceArea,
        'forestryClass': value.forestryClass,
        'metadata': MetadataToJSON(value.metadata),
    };
}


