/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Facility,
    FacilityFromJSON,
    FacilityToJSON,
    PackageSize,
    PackageSizeFromJSON,
    PackageSizeToJSON,
} from '../models';

export interface CreatePackageSizeRequest {
    packageSize: PackageSize;
    facility: Facility;
}

export interface DeletePackageSizeRequest {
    facility: Facility;
    packageSizeId: string;
}

export interface FindPackageSizeRequest {
    facility: Facility;
    packageSizeId: string;
}

export interface ListPackageSizesRequest {
    facility: Facility;
    firstResult?: number;
    maxResults?: number;
}

export interface UpdatePackageSizeRequest {
    packageSize: PackageSize;
    facility: Facility;
    packageSizeId: string;
}

/**
 * 
 */
export class PackageSizesApi extends runtime.BaseAPI {

    /**
     * Create new package size
     */
    async createPackageSizeRaw(requestParameters: CreatePackageSizeRequest): Promise<runtime.ApiResponse<PackageSize>> {
        if (requestParameters.packageSize === null || requestParameters.packageSize === undefined) {
            throw new runtime.RequiredError('packageSize','Required parameter requestParameters.packageSize was null or undefined when calling createPackageSize.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling createPackageSize.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/packageSizes`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PackageSizeToJSON(requestParameters.packageSize),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PackageSizeFromJSON(jsonValue));
    }

    /**
     * Create new package size
     */
    async createPackageSize(requestParameters: CreatePackageSizeRequest): Promise<PackageSize> {
        const response = await this.createPackageSizeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a package size
     */
    async deletePackageSizeRaw(requestParameters: DeletePackageSizeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling deletePackageSize.');
        }

        if (requestParameters.packageSizeId === null || requestParameters.packageSizeId === undefined) {
            throw new runtime.RequiredError('packageSizeId','Required parameter requestParameters.packageSizeId was null or undefined when calling deletePackageSize.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/packageSizes/{packageSizeId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"packageSizeId"}}`, encodeURIComponent(String(requestParameters.packageSizeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a package size
     */
    async deletePackageSize(requestParameters: DeletePackageSizeRequest): Promise<void> {
        await this.deletePackageSizeRaw(requestParameters);
    }

    /**
     * Find a package size
     */
    async findPackageSizeRaw(requestParameters: FindPackageSizeRequest): Promise<runtime.ApiResponse<PackageSize>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling findPackageSize.');
        }

        if (requestParameters.packageSizeId === null || requestParameters.packageSizeId === undefined) {
            throw new runtime.RequiredError('packageSizeId','Required parameter requestParameters.packageSizeId was null or undefined when calling findPackageSize.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/packageSizes/{packageSizeId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"packageSizeId"}}`, encodeURIComponent(String(requestParameters.packageSizeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PackageSizeFromJSON(jsonValue));
    }

    /**
     * Find a package size
     */
    async findPackageSize(requestParameters: FindPackageSizeRequest): Promise<PackageSize> {
        const response = await this.findPackageSizeRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all package sizes
     */
    async listPackageSizesRaw(requestParameters: ListPackageSizesRequest): Promise<runtime.ApiResponse<Array<PackageSize>>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling listPackageSizes.');
        }

        const queryParameters: any = {};

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResults !== undefined) {
            queryParameters['maxResults'] = requestParameters.maxResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/packageSizes`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PackageSizeFromJSON));
    }

    /**
     * List all package sizes
     */
    async listPackageSizes(requestParameters: ListPackageSizesRequest): Promise<Array<PackageSize>> {
        const response = await this.listPackageSizesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a package size
     */
    async updatePackageSizeRaw(requestParameters: UpdatePackageSizeRequest): Promise<runtime.ApiResponse<PackageSize>> {
        if (requestParameters.packageSize === null || requestParameters.packageSize === undefined) {
            throw new runtime.RequiredError('packageSize','Required parameter requestParameters.packageSize was null or undefined when calling updatePackageSize.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling updatePackageSize.');
        }

        if (requestParameters.packageSizeId === null || requestParameters.packageSizeId === undefined) {
            throw new runtime.RequiredError('packageSizeId','Required parameter requestParameters.packageSizeId was null or undefined when calling updatePackageSize.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/packageSizes/{packageSizeId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"packageSizeId"}}`, encodeURIComponent(String(requestParameters.packageSizeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PackageSizeToJSON(requestParameters.packageSize),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PackageSizeFromJSON(jsonValue));
    }

    /**
     * Updates a package size
     */
    async updatePackageSize(requestParameters: UpdatePackageSizeRequest): Promise<PackageSize> {
        const response = await this.updatePackageSizeRaw(requestParameters);
        return await response.value();
    }

}
