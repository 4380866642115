/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum HarvestEventType {
    Bagging = 'BAGGING',
    Cutting = 'CUTTING',
    Boxing = 'BOXING'
}

export function HarvestEventTypeFromJSON(json: any): HarvestEventType {
    return HarvestEventTypeFromJSONTyped(json, false);
}

export function HarvestEventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): HarvestEventType {
    return json as HarvestEventType;
}

export function HarvestEventTypeToJSON(value?: HarvestEventType | null): any {
    return value as any;
}

